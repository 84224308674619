import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  FeatureList,
  Box,
  Page,
  InputArea,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Tooltip,
  MarketingPageLayoutContent,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [apiKey, setApiKey] = React.useState("");
  const [secretKey, setSecretKey] = React.useState("");
  const [isBusinessAppModalOpened, setIsBusinessAppModalOpened] =
    React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=38cf9696-9952-46fb-8fe7-0e92c0e49d94&redirectUrl=https://certifiedcode.editorx.io/binance-pay-button/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/binance-pay-button/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (apiKey === "" || secretKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account =
      !open === true
        ? {
            apiKey,
            secretKey,
          }
        : {};
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account,
      }),
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setApiKey("");
        setSecretKey("");
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>Binance Pay Button</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? (
                <Button
                  skin="premium"
                  prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  {isUpgraded ? "Manage Plan" : "Accept Online Payments"}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isBusinessAppModalOpened}
            onRequestClose={() => setIsBusinessAppModalOpened(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              onCloseButtonClick={() => {
                setIsBusinessAppModalOpened(false);
              }}
              title="Advanced Integration"
              content={
                <Card>
                  <MarketingPageLayoutContent
                    title="Access to Binance Pay Integration with Wix eCommerce"
                    content={
                      <Text>
                        <ul>
                          <li>Accept payments with Wix Stores & Bookings</li>
                          <li>Seamless integration to take online payments</li>
                          <li>Auto update payment status with Wix</li>
                          <Box
                            alignContent="center"
                            gap="SP1"
                            direction="horizontal"
                          >
                            <li>Take payments everywhere in Wix</li>
                            <Badge size="tiny" skin="warningLight">
                              NEW
                            </Badge>
                          </Box>
                        </ul>
                      </Text>
                    }
                    actions={
                      <Layout>
                        <Cell>
                          <Button
                            skin="premium"
                            size="large"
                            as="a"
                            href="https://www.certifiedcode.us/integration/coinbase-commerce"
                            target="_blank"
                          >
                            Discover similar solution
                          </Button>
                        </Cell>
                        <Cell>
                          <Text size="tiny">
                            By continuing, you agree to the{" "}
                            <a
                              href="https://www.certifiedcode.us/legal/terms-of-services"
                              target="_blank"
                            >
                              Certified Code's Terms of Services
                            </a>
                          </Text>
                        </Cell>
                        <Cell></Cell>
                      </Layout>
                    }
                  />
                </Card>
              }
            />
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText="Save"
              secondaryButtonText="Cancel"
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              title="Connect to Binance Pay"
              content={
                <Page>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField label="Binance Pay API Key">
                              <Input
                                clearButton
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                              />
                            </FormField>
                            <FormField label="Binance Pay Secret Key">
                              <Input
                                clearButton
                                value={secretKey}
                                onChange={(e) => setSecretKey(e.target.value)}
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Accept online payments without limits"
              primaryButtonText="Upgrade"
              linkText="Learn more"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"38cf9696-9952-46fb-8fe7-0e92c0e49d94"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              linkOnClick={() => {
                window.open(
                  "https://support.certifiedcode.us/en/articles/7998695-getting-started-with-binance-pay-pay-button"
                );
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Accept online payments via Binance Pay Button. Upgrade to accept
                more payments.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Ops, something went wrong"
              content={
                <Text>Please refresh this page, or contact support.</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        Accept Online Payments
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                          {"Ready"}
                        </Badge>
                      ) : (
                        <FloatingHelper
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {"Limited"}
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title="Don't forget to upgrade to accept live payments!"
                              body="In trial plan, only test payments are allowed. Upgrade to accept live payments."
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      onChange={() => {
                        setSettings();
                      }}
                      size="medium"
                      checked={open}
                      disabled={isOpening || !isUpgraded}
                    />
                  }
                />
                {open && isUpgraded ? (
                  <>
                    <Card.Content>
                      <EmptyState
                        theme="section"
                        title="Great! You're ready to accept online payments."
                        subtitle="To change your Binance Pay account, please turn off “Accept Online Payments”, then turn it on again and fill in the information."
                      >
                        <TextButton
                          prefixIcon={<Icons.ExternalLink />}
                          as={"a"}
                          href={
                            (instanceData as any)["site"]
                              ? (instanceData as any)["site"]["url"]
                              : ""
                          }
                          target={"_blank"}
                        >
                          Open Live Site
                        </TextButton>
                      </EmptyState>
                    </Card.Content>{" "}
                  </>
                ) : (
                  <></>
                )}
              </Card>
            </Cell>
            <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Accept Online Payments"
                  description="With Certified Code Business Solutions, you can accept online payments from your customers via Wix Stores, Bookings and more."
                  actions={
                    <Box direction="horizontal" gap="SP1">
                      <Button
                        size="small"
                        skin="premium"
                        onClick={() => {
                          setIsBusinessAppModalOpened(true);
                        }}
                      >
                        See Pricing
                      </Button>
                      <Button
                        size="small"
                        skin="inverted"
                        onClick={() => {
                          window.Intercom(
                            "showNewMessages",
                            "I would like to accept payments with Binance Pay on Wix Stores and other Wix apps."
                          );
                        }}
                      >
                        Talk with sales
                      </Button>
                    </Box>
                  }
                  size="tiny"
                  badge={<Badge size="small">IN APP PURCHASE</Badge>}
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-style-react.com/storybook/generic_upgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Contact support"
                  description="Need help? Talk with our team to get answers to your questions."
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.Intercom(
                          "showNewMessages",
                          "I need help with Binance Pay Button."
                        );
                      }}
                    >
                      Live Chat
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-style-react.com/storybook/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
